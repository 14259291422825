import { CardElement } from '@stripe/react-stripe-js'
import { StripeCardElementOptions } from '@stripe/stripe-js'
import React, { VFC } from 'react'
import { color } from '~/style/color'
import { useCreditCardInput } from './useCreditCardInput'

const stripeElementOptions: StripeCardElementOptions = {
  style: {
    base: {
      color: color.textBlack,
    },
    invalid: {
      color: color.error,
      iconColor: color.error,
    },
  },
  hidePostalCode: true,
}

const CreditCardInput: VFC = () => {
  const { onChange, onBlur } = useCreditCardInput()

  return <CardElement onChange={onChange} onBlur={onBlur} options={stripeElementOptions} />
}

export default CreditCardInput
