import { CardElementProps, useElements, useStripe } from '@stripe/react-stripe-js'
import { useRef } from 'react'
import { useController, useFormContext } from 'react-hook-form'

export const useCreditCardInput = () => {
  const cardError = useRef<string | undefined>(undefined)
  const elements = useElements()
  const stripe = useStripe()

  const { control, setValue, trigger } = useFormContext<{ creditCard: unknown }>()
  const {
    field: { onChange: _onChange, onBlur: _onBlur },
  } = useController({
    control: control,
    name: 'creditCard',
    rules: {
      required: '入力してください。',
      validate: () => {
        return cardError.current
      },
    },
  })

  // CardElementのChange, Blur時にvalidate処理を設定
  const onChange: CardElementProps['onChange'] = (event) => {
    _onChange(event)

    cardError.current = event.error?.message

    if (!event.empty) {
      setValue('creditCard', true)
    } else {
      setValue('creditCard', null)
    }
  }

  const onBlur: CardElementProps['onBlur'] = () => {
    _onBlur()
    trigger('creditCard')
  }

  return {
    elements,
    stripe,
    onChange,
    onBlur,
  }
}
