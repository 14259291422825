import { css, cx } from '@linaria/core';
import { AriaAttributes, forwardRef, InputHTMLAttributes } from 'react';
import { theme } from '~/style';
import { color } from '~/style/color';
export const BaseInput = forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement> & {
  invalid: AriaAttributes['aria-invalid'];
}>(function BaseInput({
  invalid,
  className,
  ...props
}, ref) {
  return <input ref={ref} aria-invalid={invalid} className={cx(base, className)} {...props} />;
});
const base = "b1si6go";

require("./BaseInput.linaria.module.css!=!../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./BaseInput.tsx");