import {
  AriaAttributes,
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  TextareaHTMLAttributes,
} from 'react'
import { BaseInput } from './BaseInput'
import { Textarea } from './BaseTextarea'

const TextInput: ForwardRefRenderFunction<
  HTMLInputElement | HTMLTextAreaElement,
  | (InputHTMLAttributes<HTMLInputElement> & { invalid: AriaAttributes['aria-invalid'] })
  | (TextareaHTMLAttributes<HTMLTextAreaElement> & {
      multiple: true
      invalid: AriaAttributes['aria-invalid']
    })
> = ({ className, multiple, invalid, ...props }, ref) =>
  multiple ? (
    <Textarea
      ref={ref as ForwardedRef<HTMLTextAreaElement>}
      invalid={invalid}
      className={className}
      {...(props as TextareaHTMLAttributes<HTMLTextAreaElement>)}
    />
  ) : (
    <BaseInput
      ref={ref as ForwardedRef<HTMLInputElement>}
      invalid={invalid}
      className={className}
      {...(props as InputHTMLAttributes<HTMLInputElement>)}
    />
  )

export default forwardRef(TextInput)
