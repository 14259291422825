import { css, cx } from '@linaria/core';
import { AriaAttributes, ForwardedRef, forwardRef, ForwardRefRenderFunction, InputHTMLAttributes } from 'react';
import { color } from '~/style/color';
import { Icon } from '../Icon';
import { BaseInput } from './BaseInput';
type Props = InputHTMLAttributes<HTMLInputElement> & {
  iconPath: string;
  invalid: AriaAttributes['aria-invalid'];
};

const IconTextInput: ForwardRefRenderFunction<HTMLInputElement, Props> = ({
  className,
  iconPath,
  invalid,
  ...props
}, ref) => <span className={container}>
    <Icon path={iconPath} className={icon} color={color.textBlack20} size={20} />
    <BaseInput ref={(ref as ForwardedRef<HTMLInputElement>)} invalid={invalid} className={cx(className, extendInput)} {...(props as InputHTMLAttributes<HTMLInputElement>)} />
  </span>;

export default forwardRef(IconTextInput);
const container = "cg23emd";
const extendInput = "eo1xn3c";
const icon = "i502a9s";

require("./IconTextInput.linaria.module.css!=!../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./IconTextInput.tsx");