import { css, cx } from '@linaria/core';
import { AriaAttributes, FormEvent, forwardRef, TextareaHTMLAttributes, useCallback } from 'react';
import { theme } from '~/style';
import { color } from '~/style/color';
export const Textarea = forwardRef<HTMLTextAreaElement, TextareaHTMLAttributes<HTMLTextAreaElement> & {
  invalid: AriaAttributes['aria-invalid'];
}>(function Textarea({
  invalid,
  onInput,
  className,
  ...props
}, ref) {
  const handleInput = useCallback((event: FormEvent<HTMLTextAreaElement>) => {
    const lines = `${event.currentTarget.value}\n`.match(/\n/g)?.length ?? 1;
    const lineHeight = Number(getComputedStyle(event.currentTarget).lineHeight.match(/\d+/g)?.[0] ?? 0);
    event.currentTarget.style.height = `${Number(lineHeight) * lines + 16 * 2}px`;
    onInput && onInput(event);
  }, [onInput]);
  return <textarea ref={ref} aria-invalid={invalid} className={cx(base, className)} onInput={handleInput} {...props} />;
});
const base = "b1e2vttg";

require("./BaseTextarea.linaria.module.css!=!../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./BaseTextarea.tsx");